import { ColorsListProps } from 'core/colors-list/colors-list.props';

export const ColorsListPresets: ColorsListProps = {
  colors: [
    'canyon',
    'coral',
    'carbon',
    'fog',
    'forest',
    'meadow',
    'midnight',
    'sand',
    'sky',
    'snow',
    'sunrise',
  ],
  size: '2.4rem',
  multiple: false,
};
