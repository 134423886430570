import React, { forwardRef, useEffect, useState } from 'react';
import { ColorsListProps } from 'core/colors-list/colors-list.props';
import { ColorsListPresets } from 'core/colors-list/colors-list.presets';
import {
  StyledColor,
  StyledColorList,
  StyledColorOutline,
} from 'core/colors-list/colors-list.styles';
import { useSprings } from 'react-spring';
import { getThemeColor } from 'styles/theme';
import { convertArrayToObject } from 'utils/array';
import Link from 'next/link';

export const ColorsList = forwardRef((props: ColorsListProps, ref: any) => {
  const {
    colors,
    size,
    multiple,
    selected,
    singleLine,
    optionHref,
    optionAs,
    style,
    onChange,
  } = Object.assign({}, ColorsListPresets, props);

  const [active, setActive] = useState({});

  const [animationProps, setAnimationProps] = useSprings(
    colors.length,
    index => ({
      scale: 1,
      borderColor: getThemeColor(colors[index]),
    }),
  );

  useEffect(() => {
    const selectedValue: any = selected;

    if (multiple) {
      setActive(convertArrayToObject(selectedValue));
    } else {
      setActive({
        [selectedValue]: true,
      });
    }
  }, [selected]);

  useEffect(() => {
    setAnimationProps(index => ({
      borderColor: getThemeColor(
        active[colors[index]] ? 'primary' : colors[index],
      ),
      scale: active[colors[index]] ? 0.85 : 1,
    }));
  }, [active, colors]);

  const onColorClick = color => {
    const temporal = multiple ? { ...active } : {};
    if (temporal[color]) {
      delete temporal[color];
    } else {
      temporal[color] = true;
    }

    setActive(temporal);

    if (onChange) {
      setTimeout(() => {
        onChange(multiple ? Object.keys(temporal) : color);
      }, 200);
    }
  };

  const renderOption = (index: number, borderColor, scale) => (
    <StyledColorOutline
      key={colors[index]}
      size={size}
      color={getThemeColor(colors[index])}
      style={{ borderColor }}
      onClick={() => onColorClick(colors[index])}>
      <StyledColor color={colors[index]} size={size} style={{ scale }} />
    </StyledColorOutline>
  );

  return (
    <StyledColorList
      ref={ref}
      size={size}
      style={style}
      columnsCount={singleLine ? colors.length : null}>
      {animationProps.map(({ borderColor, scale }, index) =>
        optionHref || optionAs ? (
          <Link
            key={index}
            href={optionHref ? optionHref(colors[index]) : null}
            as={optionAs ? optionAs(colors[index]) : null}>
            <a>{renderOption(index, borderColor, scale)}</a>
          </Link>
        ) : (
          renderOption(index, borderColor, scale)
        ),
      )}
    </StyledColorList>
  );
});
