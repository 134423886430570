import { isObject } from 'lodash';

/**
 * Converts an array into an object, if the array contains objects a key must be passed,
 * which value will be used as keys for the resulting object
 * */
export const convertArrayToObject = (array: any[], key: string = null) => {
  if (!array) {
    return {};
  }

  const initialValue = {};
  return array.reduce((obj, item) => {
    const complex = isObject(item);
    const property = complex ? item[key] : item;

    return {
      ...obj,
      [property]: complex ? item : true,
    };
  }, initialValue);
};
