import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { getThemeColor } from 'styles/theme';

export const StyledColorList = styled.div<{ size: string; columnsCount }>`
  ${({ columnsCount, size, theme }) => css`
    display: grid;
    grid-template-columns: repeat(${columnsCount || 'auto-fit'}, ${size});
    column-gap: ${theme.spacing.s1};
    row-gap: ${theme.spacing.s1};
    width: inherit;
  `}
`;

export const StyledColorOutline = styled(animated.span)<{
  size: string;
  color: string;
}>`
  ${({ size, color, theme }) => css`
    background-color: ${theme.colors.white};
    width: ${size};
    height: ${size};
    border-radius: 50%;
    border: 1px solid ${color};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `}
`;

export const StyledColor = styled(animated.span)<{
  color: string;
  size: string;
}>`
  ${({ color, size }) => css`
    background-color: ${getThemeColor(color)};
    width: ${size};
    min-width: ${size};
    height: ${size};
    min-height: ${size};
    display: inline-block;
    border-radius: 50%;
    border: 1px solid ${getThemeColor('lightGrey')};
  `}
`;
